import { Box, Center, FormControl, FormErrorMessage, Input, Stack } from "@chakra-ui/react";
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";
import { FirebaseError } from "@firebase/util";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { ChakraLink } from "~/components/alias/ChakraLink";
import { NextLink } from "~/components/alias/NextLink";
import { MyFormLabel } from "~/components/form/MyFormLabel";
import { errorToMessage } from "~/libs/firebase";
import yup from "~/libs/yup";
import { Card } from "~data-display/Card";
import { pagesPath } from "~generated/$path";
import { useConfirm } from "~hooks/useConfirm";
import { useHandleInvalid } from "~hooks/useHandleInvalid";

type Inputs = {
  email: string;
  password: string;
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const Login: React.VFC = () => {
  const methods = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  const handleInvalid = useHandleInvalid();
  const confirm = useConfirm();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (inputs: Inputs) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(getAuth(), inputs.email, inputs.password);
    } catch (e) {
      if (e instanceof FirebaseError) {
        confirm(errorToMessage(e), { okOnly: true });
      } else {
        throw e;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Center h="90vh" maxW="container.sm" mx="auto" w={"full"}>
      <Box w="full">
        <Card title="ログイン">
          <Stack>
            <FormControl isInvalid={!!methods.formState.errors.email}>
              <MyFormLabel isRequired>メールアドレス</MyFormLabel>
              <Input {...methods.register("email")} />
              <FormErrorMessage>{methods.formState.errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!methods.formState.errors.password}>
              <MyFormLabel isRequired>パスワード</MyFormLabel>
              <Input type="password" {...methods.register("password")} />
              <FormErrorMessage>{methods.formState.errors.password?.message}</FormErrorMessage>
            </FormControl>

            <ChakraButton
              colorScheme="brand"
              isLoading={loading}
              onClick={methods.handleSubmit(onSubmit, handleInvalid)}
            >
              ログイン
            </ChakraButton>
          </Stack>
        </Card>

        <Box mt={2} textAlign={"right"}>
          <NextLink href={pagesPath.public.password_reset.$url()} legacyBehavior passHref>
            <ChakraLink color={"blue"}>パスワードを忘れた方</ChakraLink>
          </NextLink>
        </Box>
      </Box>
    </Center>
  );
};
