import { theme } from "@chakra-ui/theme";

export const colors = {
  body: theme.colors.gray["800"],
  brand: theme.colors.cyan,
  emphasis: "#6834f8",
  error: theme.colors.red["500"],
  muted: theme.colors.gray["500"],
  primary: theme.colors.cyan["500"],
};
