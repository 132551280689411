import { UrlObject } from "url";

import { Box, BoxProps, chakra } from "@chakra-ui/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React from "react";

import { NextLink } from "~/components/alias/NextLink";

type Props = { href: UrlObject; icon: IconDefinition; label: string };

export const AdminLayoutMenuItem: React.VFC<Props & BoxProps> = ({ icon, label, href, ...props }) => {
  const router = useRouter();
  const isCurrent = router.pathname.startsWith(href.pathname || "");

  return (
    <NextLink href={href} legacyBehavior passHref>
      <a>
        <Box
          _hover={{ opacity: 0.8 }}
          bg={isCurrent ? "cyan.400" : "white"}
          color={isCurrent ? "white" : "black"}
          fontSize={"sm"}
          fontWeight={isCurrent ? "bold" : "normal"}
          px={3}
          py={3}
          w="full"
          {...props}
        >
          <chakra.span mr={2}>
            <FontAwesomeIcon fixedWidth icon={icon} />
          </chakra.span>
          {label}
        </Box>
      </a>
    </NextLink>
  );
};
