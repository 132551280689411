import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** file */
  File: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** Represents untyped JSON */
  JSON: any;
};

export type AlertCounts = {
  __typename?: 'AlertCounts';
  delivery: Scalars['Int'];
  error: Scalars['Int'];
  shipping: Scalars['Int'];
};

/** Autogenerated input type of CreateShipment */
export type CreateShipmentInput = {
  attributes: ShipmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateShipment. */
export type CreateShipmentPayload = {
  __typename?: 'CreateShipmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shipment?: Maybe<Shipment>;
  validationErrors?: Maybe<ValidationErrors>;
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  attributes: UserAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateUser. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export type CsvError = {
  __typename?: 'CsvError';
  errors: Array<Array<Scalars['String']>>;
  line: Scalars['Int'];
};

/** Autogenerated input type of DestroyGroup */
export type DestroyGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DestroyGroup. */
export type DestroyGroupPayload = {
  __typename?: 'DestroyGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of DestroyShipment */
export type DestroyShipmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['Int']>;
};

/** Autogenerated return type of DestroyShipment. */
export type DestroyShipmentPayload = {
  __typename?: 'DestroyShipmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DestroyStatusCondition */
export type DestroyStatusConditionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DestroyStatusCondition. */
export type DestroyStatusConditionPayload = {
  __typename?: 'DestroyStatusConditionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of DestroyUser */
export type DestroyUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DestroyUser. */
export type DestroyUserPayload = {
  __typename?: 'DestroyUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of FetchTrackings */
export type FetchTrackingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['Int']>;
};

/** Autogenerated return type of FetchTrackings. */
export type FetchTrackingsPayload = {
  __typename?: 'FetchTrackingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shipments: Array<Shipment>;
};

/** Autogenerated input type of GetShipmentCsv */
export type GetShipmentCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  queries?: InputMaybe<ShipmentQueries>;
};

/** Autogenerated return type of GetShipmentCsv. */
export type GetShipmentCsvPayload = {
  __typename?: 'GetShipmentCsvPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  csv: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  aasmState: GroupAasmStateEnum;
  admin: Scalars['Boolean'];
  apiAccessKey: Scalars['String'];
  columnOptions?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  users: Array<User>;
  usersCount: Scalars['Int'];
};

export enum GroupAasmStateEnum {
  Active = 'active',
  Inactive = 'inactive'
}

export type GroupAttributes = {
  aasmState: GroupAasmStateEnum;
  admin: Scalars['Boolean'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminCreateUser?: Maybe<CreateUserPayload>;
  adminDestroyGroup?: Maybe<DestroyGroupPayload>;
  adminDestroyStatusCondition?: Maybe<DestroyStatusConditionPayload>;
  adminDestroyUser?: Maybe<DestroyUserPayload>;
  adminSaveGroup?: Maybe<SaveGroupPayload>;
  adminSaveStatusCondition?: Maybe<SaveStatusConditionPayload>;
  createShipment?: Maybe<CreateShipmentPayload>;
  destroyShipment?: Maybe<DestroyShipmentPayload>;
  fetchTrackings?: Maybe<FetchTrackingsPayload>;
  getShipmentCsv?: Maybe<GetShipmentCsvPayload>;
  updateColumnOptions?: Maybe<UpdateColumnOptionsPayload>;
  updateShipment?: Maybe<UpdateShipmentPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  uploadCsv?: Maybe<UploadCsvPayload>;
};


export type MutationAdminCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationAdminDestroyGroupArgs = {
  input: DestroyGroupInput;
};


export type MutationAdminDestroyStatusConditionArgs = {
  input: DestroyStatusConditionInput;
};


export type MutationAdminDestroyUserArgs = {
  input: DestroyUserInput;
};


export type MutationAdminSaveGroupArgs = {
  input: SaveGroupInput;
};


export type MutationAdminSaveStatusConditionArgs = {
  input: SaveStatusConditionInput;
};


export type MutationCreateShipmentArgs = {
  input: CreateShipmentInput;
};


export type MutationDestroyShipmentArgs = {
  input: DestroyShipmentInput;
};


export type MutationFetchTrackingsArgs = {
  input: FetchTrackingsInput;
};


export type MutationGetShipmentCsvArgs = {
  input: GetShipmentCsvInput;
};


export type MutationUpdateColumnOptionsArgs = {
  input: UpdateColumnOptionsInput;
};


export type MutationUpdateShipmentArgs = {
  input: UpdateShipmentInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUploadCsvArgs = {
  input: UploadCsvInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int'];
  firstPage: Scalars['Boolean'];
  lastPage: Scalars['Boolean'];
  limitValue?: Maybe<Scalars['Int']>;
  nextPage?: Maybe<Scalars['Int']>;
  prevPage?: Maybe<Scalars['Int']>;
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  adminGroup: Group;
  adminGroups: Array<Group>;
  adminStatusConditions: Array<StatusCondition>;
  alertCounts?: Maybe<AlertCounts>;
  errorShipmentCount?: Maybe<Scalars['Int']>;
  me?: Maybe<User>;
  shipment: Shipment;
  shipments?: Maybe<Shipments>;
};


export type QueryAdminGroupArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryShipmentArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryShipmentsArgs = {
  queries: ShipmentQueries;
};

/** Autogenerated input type of SaveGroup */
export type SaveGroupInput = {
  attributes: GroupAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SaveGroup. */
export type SaveGroupPayload = {
  __typename?: 'SaveGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  group: Group;
};

/** Autogenerated input type of SaveStatusCondition */
export type SaveStatusConditionInput = {
  attributes: StatusConditionAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SaveStatusCondition. */
export type SaveStatusConditionPayload = {
  __typename?: 'SaveStatusConditionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  statusCondition: StatusCondition;
};

export type Shipment = {
  __typename?: 'Shipment';
  createdAt: Scalars['ISO8601DateTime'];
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
  deliveryAlert?: Maybe<Scalars['Boolean']>;
  deliveryAlertOn?: Maybe<Scalars['ISO8601Date']>;
  fetchedAt?: Maybe<Scalars['ISO8601DateTime']>;
  general1?: Maybe<Scalars['String']>;
  general2?: Maybe<Scalars['String']>;
  general3?: Maybe<Scalars['String']>;
  general4?: Maybe<Scalars['String']>;
  general5?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  latestTrackedAt?: Maybe<Scalars['ISO8601DateTime']>;
  latestTrackingStatus?: Maybe<Scalars['String']>;
  shippedAt?: Maybe<Scalars['ISO8601DateTime']>;
  shippingAlert: Scalars['Boolean'];
  shippingAlertOn?: Maybe<Scalars['ISO8601Date']>;
  status: ShipmentStatusEnum;
  statusText: Scalars['String'];
  trackingNumber: Scalars['String'];
  trackings?: Maybe<Scalars['JSON']>;
  type: ShipmentTypeEnum;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ShipmentAttributes = {
  deliveryAlertOn?: InputMaybe<Scalars['String']>;
  general1?: InputMaybe<Scalars['String']>;
  general2?: InputMaybe<Scalars['String']>;
  general3?: InputMaybe<Scalars['String']>;
  general4?: InputMaybe<Scalars['String']>;
  general5?: InputMaybe<Scalars['String']>;
  shippingAlertOn?: InputMaybe<Scalars['String']>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ShipmentQueries = {
  createdAt?: InputMaybe<Array<Scalars['String']>>;
  deliveredAt?: InputMaybe<Array<Scalars['String']>>;
  deliveryAlert?: InputMaybe<Scalars['Boolean']>;
  deliveryAlertOn?: InputMaybe<Array<Scalars['String']>>;
  general1?: InputMaybe<Array<Scalars['String']>>;
  general2?: InputMaybe<Array<Scalars['String']>>;
  general3?: InputMaybe<Array<Scalars['String']>>;
  general4?: InputMaybe<Array<Scalars['String']>>;
  general5?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Array<Scalars['String']>>;
  latestTrackingStatus?: InputMaybe<Array<Scalars['String']>>;
  notShippedAlert?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
  shippedAt?: InputMaybe<Array<Scalars['String']>>;
  shippingAlert?: InputMaybe<Scalars['Boolean']>;
  shippingAlertOn?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  trackingNumber?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export enum ShipmentStatusEnum {
  Delivered = 'delivered',
  Error = 'error',
  Initial = 'initial',
  NotRegistered = 'not_registered',
  Researching = 'researching',
  Shipped = 'shipped',
  Undefined = 'undefined'
}

export enum ShipmentTypeEnum {
  Hukuyama = 'Hukuyama',
  Sagawa = 'Sagawa',
  Seino = 'Seino',
  Yamato = 'Yamato',
  YouPack = 'YouPack'
}

export type Shipments = {
  __typename?: 'Shipments';
  pageInfo: PageInfo;
  shipments: Array<Shipment>;
};

export type StatusCondition = {
  __typename?: 'StatusCondition';
  id: Scalars['Int'];
  matchType: StatusConditionMatchTypeEnum;
  shipmentType: ShipmentTypeEnum;
  status: ShipmentStatusEnum;
  value: Scalars['String'];
};

export type StatusConditionAttributes = {
  id?: InputMaybe<Scalars['Int']>;
  matchType: StatusConditionMatchTypeEnum;
  shipmentType: ShipmentTypeEnum;
  status: ShipmentStatusEnum;
  value: Scalars['String'];
};

export enum StatusConditionMatchTypeEnum {
  Exact = 'exact',
  Forward = 'forward'
}

/** Autogenerated input type of UpdateColumnOptions */
export type UpdateColumnOptionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  columnOptions: Scalars['JSON'];
};

/** Autogenerated return type of UpdateColumnOptions. */
export type UpdateColumnOptionsPayload = {
  __typename?: 'UpdateColumnOptionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  group?: Maybe<Group>;
};

/** Autogenerated input type of UpdateShipment */
export type UpdateShipmentInput = {
  attributes: ShipmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateShipment. */
export type UpdateShipmentPayload = {
  __typename?: 'UpdateShipmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shipment?: Maybe<Shipment>;
  validationErrors?: Maybe<ValidationErrors>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  receiveAlertEmail?: InputMaybe<Scalars['Boolean']>;
  receiveArchiveEmail?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UploadCsv */
export type UploadCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  file: Scalars['File'];
};

/** Autogenerated return type of UploadCsv. */
export type UploadCsvPayload = {
  __typename?: 'UploadCsvPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<CsvError>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  firebaseUid: Scalars['String'];
  group: Group;
  id: Scalars['Int'];
  receiveAlertEmail: Scalars['Boolean'];
  receiveArchiveEmail: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type UserAttributes = {
  email: Scalars['String'];
  groupId: Scalars['Int'];
};

export type ValidationError = {
  __typename?: 'ValidationError';
  messages: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  errors: Array<ValidationError>;
};

export type GroupFragment = { __typename?: 'Group', apiAccessKey: string, admin: boolean, id: number, columnOptions?: any | null, name: string, aasmState: GroupAasmStateEnum, createdAt: string, updatedAt: string, usersCount: number };

export type PageInfoFragment = { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number, nextPage?: number | null, prevPage?: number | null, lastPage: boolean, firstPage: boolean };

export type ShipmentFragment = { __typename?: 'Shipment', createdAt: string, deliveredAt?: string | null, deliveryAlert?: boolean | null, deliveryAlertOn?: string | null, fetchedAt?: string | null, general1?: string | null, general2?: string | null, general3?: string | null, general4?: string | null, general5?: string | null, id: number, latestTrackedAt?: string | null, latestTrackingStatus?: string | null, shippedAt?: string | null, shippingAlert: boolean, shippingAlertOn?: string | null, status: ShipmentStatusEnum, statusText: string, trackingNumber: string, trackings?: any | null, type: ShipmentTypeEnum, updatedAt: string };

export type StatusConditionFragment = { __typename?: 'StatusCondition', id: number, matchType: StatusConditionMatchTypeEnum, shipmentType: ShipmentTypeEnum, status: ShipmentStatusEnum, value: string };

export type UserFragment = { __typename?: 'User', id: number, receiveAlertEmail: boolean, receiveArchiveEmail: boolean, email?: string | null, firebaseUid: string, createdAt: string, group: { __typename?: 'Group', apiAccessKey: string, admin: boolean, id: number, columnOptions?: any | null, name: string, aasmState: GroupAasmStateEnum, createdAt: string, updatedAt: string, usersCount: number } };

export type CreateShipmentMutationVariables = Exact<{
  input: CreateShipmentInput;
}>;


export type CreateShipmentMutation = { __typename?: 'Mutation', createShipment?: { __typename?: 'CreateShipmentPayload', shipment?: { __typename?: 'Shipment', createdAt: string, deliveredAt?: string | null, deliveryAlert?: boolean | null, deliveryAlertOn?: string | null, fetchedAt?: string | null, general1?: string | null, general2?: string | null, general3?: string | null, general4?: string | null, general5?: string | null, id: number, latestTrackedAt?: string | null, latestTrackingStatus?: string | null, shippedAt?: string | null, shippingAlert: boolean, shippingAlertOn?: string | null, status: ShipmentStatusEnum, statusText: string, trackingNumber: string, trackings?: any | null, type: ShipmentTypeEnum, updatedAt: string } | null, validationErrors?: { __typename?: 'ValidationErrors', errors: Array<{ __typename?: 'ValidationError', name: string, messages: Array<string> }> } | null } | null };

export type DestroyShipmentMutationVariables = Exact<{
  input: DestroyShipmentInput;
}>;


export type DestroyShipmentMutation = { __typename?: 'Mutation', destroyShipment?: { __typename?: 'DestroyShipmentPayload', result?: string | null } | null };

export type FetchTrackingsCsvMutationVariables = Exact<{
  input: FetchTrackingsInput;
}>;


export type FetchTrackingsCsvMutation = { __typename?: 'Mutation', fetchTrackings?: { __typename?: 'FetchTrackingsPayload', shipments: Array<{ __typename?: 'Shipment', createdAt: string, deliveredAt?: string | null, deliveryAlert?: boolean | null, deliveryAlertOn?: string | null, fetchedAt?: string | null, general1?: string | null, general2?: string | null, general3?: string | null, general4?: string | null, general5?: string | null, id: number, latestTrackedAt?: string | null, latestTrackingStatus?: string | null, shippedAt?: string | null, shippingAlert: boolean, shippingAlertOn?: string | null, status: ShipmentStatusEnum, statusText: string, trackingNumber: string, trackings?: any | null, type: ShipmentTypeEnum, updatedAt: string }> } | null };

export type GetShipmentCsvMutationVariables = Exact<{
  input: GetShipmentCsvInput;
}>;


export type GetShipmentCsvMutation = { __typename?: 'Mutation', getShipmentCsv?: { __typename?: 'GetShipmentCsvPayload', csv: string } | null };

export type UpdateColumnOptionsMutationVariables = Exact<{
  input: UpdateColumnOptionsInput;
}>;


export type UpdateColumnOptionsMutation = { __typename?: 'Mutation', updateColumnOptions?: { __typename?: 'UpdateColumnOptionsPayload', group?: { __typename?: 'Group', apiAccessKey: string, admin: boolean, id: number, columnOptions?: any | null, name: string, aasmState: GroupAasmStateEnum, createdAt: string, updatedAt: string, usersCount: number } | null } | null };

export type UpdateShipmentMutationVariables = Exact<{
  input: UpdateShipmentInput;
}>;


export type UpdateShipmentMutation = { __typename?: 'Mutation', updateShipment?: { __typename?: 'UpdateShipmentPayload', shipment?: { __typename?: 'Shipment', createdAt: string, deliveredAt?: string | null, deliveryAlert?: boolean | null, deliveryAlertOn?: string | null, fetchedAt?: string | null, general1?: string | null, general2?: string | null, general3?: string | null, general4?: string | null, general5?: string | null, id: number, latestTrackedAt?: string | null, latestTrackingStatus?: string | null, shippedAt?: string | null, shippingAlert: boolean, shippingAlertOn?: string | null, status: ShipmentStatusEnum, statusText: string, trackingNumber: string, trackings?: any | null, type: ShipmentTypeEnum, updatedAt: string } | null, validationErrors?: { __typename?: 'ValidationErrors', errors: Array<{ __typename?: 'ValidationError', name: string, messages: Array<string> }> } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'UpdateUserPayload', user?: { __typename?: 'User', id: number, receiveAlertEmail: boolean, receiveArchiveEmail: boolean, email?: string | null, firebaseUid: string, createdAt: string, group: { __typename?: 'Group', apiAccessKey: string, admin: boolean, id: number, columnOptions?: any | null, name: string, aasmState: GroupAasmStateEnum, createdAt: string, updatedAt: string, usersCount: number } } | null } | null };

export type UploadCsvMutationVariables = Exact<{
  input: UploadCsvInput;
}>;


export type UploadCsvMutation = { __typename?: 'Mutation', uploadCsv?: { __typename?: 'UploadCsvPayload', errors: Array<{ __typename?: 'CsvError', errors: Array<Array<string>>, line: number }> } | null };

export type AdminCreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type AdminCreateUserMutation = { __typename?: 'Mutation', adminCreateUser?: { __typename?: 'CreateUserPayload', user: { __typename?: 'User', id: number, receiveAlertEmail: boolean, receiveArchiveEmail: boolean, email?: string | null, firebaseUid: string, createdAt: string, group: { __typename?: 'Group', apiAccessKey: string, admin: boolean, id: number, columnOptions?: any | null, name: string, aasmState: GroupAasmStateEnum, createdAt: string, updatedAt: string, usersCount: number } } } | null };

export type AdminDestroyGroupMutationVariables = Exact<{
  input: DestroyGroupInput;
}>;


export type AdminDestroyGroupMutation = { __typename?: 'Mutation', adminDestroyGroup?: { __typename?: 'DestroyGroupPayload', result: boolean } | null };

export type AdminDestroyStatusConditionMutationVariables = Exact<{
  input: DestroyStatusConditionInput;
}>;


export type AdminDestroyStatusConditionMutation = { __typename?: 'Mutation', adminDestroyStatusCondition?: { __typename?: 'DestroyStatusConditionPayload', result: boolean } | null };

export type AdminDestroyUserMutationVariables = Exact<{
  input: DestroyUserInput;
}>;


export type AdminDestroyUserMutation = { __typename?: 'Mutation', adminDestroyUser?: { __typename?: 'DestroyUserPayload', result: boolean } | null };

export type AdminSaveGroupMutationVariables = Exact<{
  input: SaveGroupInput;
}>;


export type AdminSaveGroupMutation = { __typename?: 'Mutation', adminSaveGroup?: { __typename?: 'SaveGroupPayload', group: { __typename?: 'Group', apiAccessKey: string, admin: boolean, id: number, columnOptions?: any | null, name: string, aasmState: GroupAasmStateEnum, createdAt: string, updatedAt: string, usersCount: number } } | null };

export type AdminSaveStatusConditionMutationVariables = Exact<{
  input: SaveStatusConditionInput;
}>;


export type AdminSaveStatusConditionMutation = { __typename?: 'Mutation', adminSaveStatusCondition?: { __typename?: 'SaveStatusConditionPayload', statusCondition: { __typename?: 'StatusCondition', id: number, matchType: StatusConditionMatchTypeEnum, shipmentType: ShipmentTypeEnum, status: ShipmentStatusEnum, value: string } } | null };

export type AlertCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AlertCountsQuery = { __typename?: 'Query', alertCounts?: { __typename?: 'AlertCounts', delivery: number, error: number, shipping: number } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: number, receiveAlertEmail: boolean, receiveArchiveEmail: boolean, email?: string | null, firebaseUid: string, createdAt: string, group: { __typename?: 'Group', apiAccessKey: string, admin: boolean, id: number, columnOptions?: any | null, name: string, aasmState: GroupAasmStateEnum, createdAt: string, updatedAt: string, usersCount: number } } | null };

export type ShipmentQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type ShipmentQuery = { __typename?: 'Query', shipment: { __typename?: 'Shipment', createdAt: string, deliveredAt?: string | null, deliveryAlert?: boolean | null, deliveryAlertOn?: string | null, fetchedAt?: string | null, general1?: string | null, general2?: string | null, general3?: string | null, general4?: string | null, general5?: string | null, id: number, latestTrackedAt?: string | null, latestTrackingStatus?: string | null, shippedAt?: string | null, shippingAlert: boolean, shippingAlertOn?: string | null, status: ShipmentStatusEnum, statusText: string, trackingNumber: string, trackings?: any | null, type: ShipmentTypeEnum, updatedAt: string } };

export type ShipmentsQueryVariables = Exact<{
  queries: ShipmentQueries;
}>;


export type ShipmentsQuery = { __typename?: 'Query', shipments?: { __typename?: 'Shipments', shipments: Array<{ __typename?: 'Shipment', createdAt: string, deliveredAt?: string | null, deliveryAlert?: boolean | null, deliveryAlertOn?: string | null, fetchedAt?: string | null, general1?: string | null, general2?: string | null, general3?: string | null, general4?: string | null, general5?: string | null, id: number, latestTrackedAt?: string | null, latestTrackingStatus?: string | null, shippedAt?: string | null, shippingAlert: boolean, shippingAlertOn?: string | null, status: ShipmentStatusEnum, statusText: string, trackingNumber: string, trackings?: any | null, type: ShipmentTypeEnum, updatedAt: string }>, pageInfo: { __typename?: 'PageInfo', currentPage: number, totalCount: number, totalPages: number, nextPage?: number | null, prevPage?: number | null, lastPage: boolean, firstPage: boolean } } | null };

export type AdminGroupQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AdminGroupQuery = { __typename?: 'Query', adminGroup: { __typename?: 'Group', apiAccessKey: string, admin: boolean, id: number, columnOptions?: any | null, name: string, aasmState: GroupAasmStateEnum, createdAt: string, updatedAt: string, usersCount: number, users: Array<{ __typename?: 'User', id: number, receiveAlertEmail: boolean, receiveArchiveEmail: boolean, email?: string | null, firebaseUid: string, createdAt: string, group: { __typename?: 'Group', apiAccessKey: string, admin: boolean, id: number, columnOptions?: any | null, name: string, aasmState: GroupAasmStateEnum, createdAt: string, updatedAt: string, usersCount: number } }> } };

export type AdminGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminGroupsQuery = { __typename?: 'Query', adminGroups: Array<{ __typename?: 'Group', apiAccessKey: string, admin: boolean, id: number, columnOptions?: any | null, name: string, aasmState: GroupAasmStateEnum, createdAt: string, updatedAt: string, usersCount: number }> };

export type AdminStatusConditionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminStatusConditionsQuery = { __typename?: 'Query', adminStatusConditions: Array<{ __typename?: 'StatusCondition', id: number, matchType: StatusConditionMatchTypeEnum, shipmentType: ShipmentTypeEnum, status: ShipmentStatusEnum, value: string }> };

export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  currentPage
  totalCount
  totalPages
  nextPage
  prevPage
  lastPage
  firstPage
}
    `;
export const ShipmentFragmentDoc = gql`
    fragment Shipment on Shipment {
  createdAt
  deliveredAt
  deliveryAlert
  deliveryAlertOn
  fetchedAt
  general1
  general2
  general3
  general4
  general5
  id
  latestTrackedAt
  latestTrackingStatus
  shippedAt
  shippingAlert
  shippingAlertOn
  status
  statusText
  trackingNumber
  trackings
  type
  updatedAt
}
    `;
export const StatusConditionFragmentDoc = gql`
    fragment StatusCondition on StatusCondition {
  id
  matchType
  shipmentType
  status
  value
}
    `;
export const GroupFragmentDoc = gql`
    fragment Group on Group {
  apiAccessKey
  admin
  id
  columnOptions
  name
  aasmState
  createdAt
  updatedAt
  usersCount
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  group {
    ...Group
  }
  id
  receiveAlertEmail
  receiveArchiveEmail
  email
  firebaseUid
  createdAt
}
    ${GroupFragmentDoc}`;
export const CreateShipmentDocument = gql`
    mutation CreateShipment($input: CreateShipmentInput!) {
  createShipment(input: $input) {
    shipment {
      ...Shipment
    }
    validationErrors {
      errors {
        name
        messages
      }
    }
  }
}
    ${ShipmentFragmentDoc}`;
export type CreateShipmentMutationFn = Apollo.MutationFunction<CreateShipmentMutation, CreateShipmentMutationVariables>;

/**
 * __useCreateShipmentMutation__
 *
 * To run a mutation, you first call `useCreateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShipmentMutation, { data, loading, error }] = useCreateShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShipmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateShipmentMutation, CreateShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShipmentMutation, CreateShipmentMutationVariables>(CreateShipmentDocument, options);
      }
export type CreateShipmentMutationHookResult = ReturnType<typeof useCreateShipmentMutation>;
export type CreateShipmentMutationResult = Apollo.MutationResult<CreateShipmentMutation>;
export type CreateShipmentMutationOptions = Apollo.BaseMutationOptions<CreateShipmentMutation, CreateShipmentMutationVariables>;
export const DestroyShipmentDocument = gql`
    mutation DestroyShipment($input: DestroyShipmentInput!) {
  destroyShipment(input: $input) {
    result
  }
}
    `;
export type DestroyShipmentMutationFn = Apollo.MutationFunction<DestroyShipmentMutation, DestroyShipmentMutationVariables>;

/**
 * __useDestroyShipmentMutation__
 *
 * To run a mutation, you first call `useDestroyShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyShipmentMutation, { data, loading, error }] = useDestroyShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyShipmentMutation(baseOptions?: Apollo.MutationHookOptions<DestroyShipmentMutation, DestroyShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyShipmentMutation, DestroyShipmentMutationVariables>(DestroyShipmentDocument, options);
      }
export type DestroyShipmentMutationHookResult = ReturnType<typeof useDestroyShipmentMutation>;
export type DestroyShipmentMutationResult = Apollo.MutationResult<DestroyShipmentMutation>;
export type DestroyShipmentMutationOptions = Apollo.BaseMutationOptions<DestroyShipmentMutation, DestroyShipmentMutationVariables>;
export const FetchTrackingsCsvDocument = gql`
    mutation FetchTrackingsCsv($input: FetchTrackingsInput!) {
  fetchTrackings(input: $input) {
    shipments {
      ...Shipment
    }
  }
}
    ${ShipmentFragmentDoc}`;
export type FetchTrackingsCsvMutationFn = Apollo.MutationFunction<FetchTrackingsCsvMutation, FetchTrackingsCsvMutationVariables>;

/**
 * __useFetchTrackingsCsvMutation__
 *
 * To run a mutation, you first call `useFetchTrackingsCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchTrackingsCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchTrackingsCsvMutation, { data, loading, error }] = useFetchTrackingsCsvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchTrackingsCsvMutation(baseOptions?: Apollo.MutationHookOptions<FetchTrackingsCsvMutation, FetchTrackingsCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FetchTrackingsCsvMutation, FetchTrackingsCsvMutationVariables>(FetchTrackingsCsvDocument, options);
      }
export type FetchTrackingsCsvMutationHookResult = ReturnType<typeof useFetchTrackingsCsvMutation>;
export type FetchTrackingsCsvMutationResult = Apollo.MutationResult<FetchTrackingsCsvMutation>;
export type FetchTrackingsCsvMutationOptions = Apollo.BaseMutationOptions<FetchTrackingsCsvMutation, FetchTrackingsCsvMutationVariables>;
export const GetShipmentCsvDocument = gql`
    mutation GetShipmentCsv($input: GetShipmentCsvInput!) {
  getShipmentCsv(input: $input) {
    csv
  }
}
    `;
export type GetShipmentCsvMutationFn = Apollo.MutationFunction<GetShipmentCsvMutation, GetShipmentCsvMutationVariables>;

/**
 * __useGetShipmentCsvMutation__
 *
 * To run a mutation, you first call `useGetShipmentCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getShipmentCsvMutation, { data, loading, error }] = useGetShipmentCsvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShipmentCsvMutation(baseOptions?: Apollo.MutationHookOptions<GetShipmentCsvMutation, GetShipmentCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetShipmentCsvMutation, GetShipmentCsvMutationVariables>(GetShipmentCsvDocument, options);
      }
export type GetShipmentCsvMutationHookResult = ReturnType<typeof useGetShipmentCsvMutation>;
export type GetShipmentCsvMutationResult = Apollo.MutationResult<GetShipmentCsvMutation>;
export type GetShipmentCsvMutationOptions = Apollo.BaseMutationOptions<GetShipmentCsvMutation, GetShipmentCsvMutationVariables>;
export const UpdateColumnOptionsDocument = gql`
    mutation UpdateColumnOptions($input: UpdateColumnOptionsInput!) {
  updateColumnOptions(input: $input) {
    group {
      ...Group
    }
  }
}
    ${GroupFragmentDoc}`;
export type UpdateColumnOptionsMutationFn = Apollo.MutationFunction<UpdateColumnOptionsMutation, UpdateColumnOptionsMutationVariables>;

/**
 * __useUpdateColumnOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateColumnOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateColumnOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateColumnOptionsMutation, { data, loading, error }] = useUpdateColumnOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateColumnOptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateColumnOptionsMutation, UpdateColumnOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateColumnOptionsMutation, UpdateColumnOptionsMutationVariables>(UpdateColumnOptionsDocument, options);
      }
export type UpdateColumnOptionsMutationHookResult = ReturnType<typeof useUpdateColumnOptionsMutation>;
export type UpdateColumnOptionsMutationResult = Apollo.MutationResult<UpdateColumnOptionsMutation>;
export type UpdateColumnOptionsMutationOptions = Apollo.BaseMutationOptions<UpdateColumnOptionsMutation, UpdateColumnOptionsMutationVariables>;
export const UpdateShipmentDocument = gql`
    mutation UpdateShipment($input: UpdateShipmentInput!) {
  updateShipment(input: $input) {
    shipment {
      ...Shipment
    }
    validationErrors {
      errors {
        name
        messages
      }
    }
  }
}
    ${ShipmentFragmentDoc}`;
export type UpdateShipmentMutationFn = Apollo.MutationFunction<UpdateShipmentMutation, UpdateShipmentMutationVariables>;

/**
 * __useUpdateShipmentMutation__
 *
 * To run a mutation, you first call `useUpdateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipmentMutation, { data, loading, error }] = useUpdateShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShipmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShipmentMutation, UpdateShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShipmentMutation, UpdateShipmentMutationVariables>(UpdateShipmentDocument, options);
      }
export type UpdateShipmentMutationHookResult = ReturnType<typeof useUpdateShipmentMutation>;
export type UpdateShipmentMutationResult = Apollo.MutationResult<UpdateShipmentMutation>;
export type UpdateShipmentMutationOptions = Apollo.BaseMutationOptions<UpdateShipmentMutation, UpdateShipmentMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadCsvDocument = gql`
    mutation UploadCsv($input: UploadCsvInput!) {
  uploadCsv(input: $input) {
    errors {
      errors
      line
    }
  }
}
    `;
export type UploadCsvMutationFn = Apollo.MutationFunction<UploadCsvMutation, UploadCsvMutationVariables>;

/**
 * __useUploadCsvMutation__
 *
 * To run a mutation, you first call `useUploadCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCsvMutation, { data, loading, error }] = useUploadCsvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadCsvMutation(baseOptions?: Apollo.MutationHookOptions<UploadCsvMutation, UploadCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadCsvMutation, UploadCsvMutationVariables>(UploadCsvDocument, options);
      }
export type UploadCsvMutationHookResult = ReturnType<typeof useUploadCsvMutation>;
export type UploadCsvMutationResult = Apollo.MutationResult<UploadCsvMutation>;
export type UploadCsvMutationOptions = Apollo.BaseMutationOptions<UploadCsvMutation, UploadCsvMutationVariables>;
export const AdminCreateUserDocument = gql`
    mutation AdminCreateUser($input: CreateUserInput!) {
  adminCreateUser(input: $input) {
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type AdminCreateUserMutationFn = Apollo.MutationFunction<AdminCreateUserMutation, AdminCreateUserMutationVariables>;

/**
 * __useAdminCreateUserMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserMutation, { data, loading, error }] = useAdminCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateUserMutation, AdminCreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateUserMutation, AdminCreateUserMutationVariables>(AdminCreateUserDocument, options);
      }
export type AdminCreateUserMutationHookResult = ReturnType<typeof useAdminCreateUserMutation>;
export type AdminCreateUserMutationResult = Apollo.MutationResult<AdminCreateUserMutation>;
export type AdminCreateUserMutationOptions = Apollo.BaseMutationOptions<AdminCreateUserMutation, AdminCreateUserMutationVariables>;
export const AdminDestroyGroupDocument = gql`
    mutation AdminDestroyGroup($input: DestroyGroupInput!) {
  adminDestroyGroup(input: $input) {
    result
  }
}
    `;
export type AdminDestroyGroupMutationFn = Apollo.MutationFunction<AdminDestroyGroupMutation, AdminDestroyGroupMutationVariables>;

/**
 * __useAdminDestroyGroupMutation__
 *
 * To run a mutation, you first call `useAdminDestroyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDestroyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDestroyGroupMutation, { data, loading, error }] = useAdminDestroyGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDestroyGroupMutation(baseOptions?: Apollo.MutationHookOptions<AdminDestroyGroupMutation, AdminDestroyGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDestroyGroupMutation, AdminDestroyGroupMutationVariables>(AdminDestroyGroupDocument, options);
      }
export type AdminDestroyGroupMutationHookResult = ReturnType<typeof useAdminDestroyGroupMutation>;
export type AdminDestroyGroupMutationResult = Apollo.MutationResult<AdminDestroyGroupMutation>;
export type AdminDestroyGroupMutationOptions = Apollo.BaseMutationOptions<AdminDestroyGroupMutation, AdminDestroyGroupMutationVariables>;
export const AdminDestroyStatusConditionDocument = gql`
    mutation AdminDestroyStatusCondition($input: DestroyStatusConditionInput!) {
  adminDestroyStatusCondition(input: $input) {
    result
  }
}
    `;
export type AdminDestroyStatusConditionMutationFn = Apollo.MutationFunction<AdminDestroyStatusConditionMutation, AdminDestroyStatusConditionMutationVariables>;

/**
 * __useAdminDestroyStatusConditionMutation__
 *
 * To run a mutation, you first call `useAdminDestroyStatusConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDestroyStatusConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDestroyStatusConditionMutation, { data, loading, error }] = useAdminDestroyStatusConditionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDestroyStatusConditionMutation(baseOptions?: Apollo.MutationHookOptions<AdminDestroyStatusConditionMutation, AdminDestroyStatusConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDestroyStatusConditionMutation, AdminDestroyStatusConditionMutationVariables>(AdminDestroyStatusConditionDocument, options);
      }
export type AdminDestroyStatusConditionMutationHookResult = ReturnType<typeof useAdminDestroyStatusConditionMutation>;
export type AdminDestroyStatusConditionMutationResult = Apollo.MutationResult<AdminDestroyStatusConditionMutation>;
export type AdminDestroyStatusConditionMutationOptions = Apollo.BaseMutationOptions<AdminDestroyStatusConditionMutation, AdminDestroyStatusConditionMutationVariables>;
export const AdminDestroyUserDocument = gql`
    mutation AdminDestroyUser($input: DestroyUserInput!) {
  adminDestroyUser(input: $input) {
    result
  }
}
    `;
export type AdminDestroyUserMutationFn = Apollo.MutationFunction<AdminDestroyUserMutation, AdminDestroyUserMutationVariables>;

/**
 * __useAdminDestroyUserMutation__
 *
 * To run a mutation, you first call `useAdminDestroyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDestroyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDestroyUserMutation, { data, loading, error }] = useAdminDestroyUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDestroyUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminDestroyUserMutation, AdminDestroyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDestroyUserMutation, AdminDestroyUserMutationVariables>(AdminDestroyUserDocument, options);
      }
export type AdminDestroyUserMutationHookResult = ReturnType<typeof useAdminDestroyUserMutation>;
export type AdminDestroyUserMutationResult = Apollo.MutationResult<AdminDestroyUserMutation>;
export type AdminDestroyUserMutationOptions = Apollo.BaseMutationOptions<AdminDestroyUserMutation, AdminDestroyUserMutationVariables>;
export const AdminSaveGroupDocument = gql`
    mutation AdminSaveGroup($input: SaveGroupInput!) {
  adminSaveGroup(input: $input) {
    group {
      ...Group
    }
  }
}
    ${GroupFragmentDoc}`;
export type AdminSaveGroupMutationFn = Apollo.MutationFunction<AdminSaveGroupMutation, AdminSaveGroupMutationVariables>;

/**
 * __useAdminSaveGroupMutation__
 *
 * To run a mutation, you first call `useAdminSaveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveGroupMutation, { data, loading, error }] = useAdminSaveGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminSaveGroupMutation(baseOptions?: Apollo.MutationHookOptions<AdminSaveGroupMutation, AdminSaveGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminSaveGroupMutation, AdminSaveGroupMutationVariables>(AdminSaveGroupDocument, options);
      }
export type AdminSaveGroupMutationHookResult = ReturnType<typeof useAdminSaveGroupMutation>;
export type AdminSaveGroupMutationResult = Apollo.MutationResult<AdminSaveGroupMutation>;
export type AdminSaveGroupMutationOptions = Apollo.BaseMutationOptions<AdminSaveGroupMutation, AdminSaveGroupMutationVariables>;
export const AdminSaveStatusConditionDocument = gql`
    mutation AdminSaveStatusCondition($input: SaveStatusConditionInput!) {
  adminSaveStatusCondition(input: $input) {
    statusCondition {
      ...StatusCondition
    }
  }
}
    ${StatusConditionFragmentDoc}`;
export type AdminSaveStatusConditionMutationFn = Apollo.MutationFunction<AdminSaveStatusConditionMutation, AdminSaveStatusConditionMutationVariables>;

/**
 * __useAdminSaveStatusConditionMutation__
 *
 * To run a mutation, you first call `useAdminSaveStatusConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveStatusConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveStatusConditionMutation, { data, loading, error }] = useAdminSaveStatusConditionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminSaveStatusConditionMutation(baseOptions?: Apollo.MutationHookOptions<AdminSaveStatusConditionMutation, AdminSaveStatusConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminSaveStatusConditionMutation, AdminSaveStatusConditionMutationVariables>(AdminSaveStatusConditionDocument, options);
      }
export type AdminSaveStatusConditionMutationHookResult = ReturnType<typeof useAdminSaveStatusConditionMutation>;
export type AdminSaveStatusConditionMutationResult = Apollo.MutationResult<AdminSaveStatusConditionMutation>;
export type AdminSaveStatusConditionMutationOptions = Apollo.BaseMutationOptions<AdminSaveStatusConditionMutation, AdminSaveStatusConditionMutationVariables>;
export const AlertCountsDocument = gql`
    query AlertCounts {
  alertCounts {
    delivery
    error
    shipping
  }
}
    `;

/**
 * __useAlertCountsQuery__
 *
 * To run a query within a React component, call `useAlertCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAlertCountsQuery(baseOptions?: Apollo.QueryHookOptions<AlertCountsQuery, AlertCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AlertCountsQuery, AlertCountsQueryVariables>(AlertCountsDocument, options);
      }
export function useAlertCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AlertCountsQuery, AlertCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AlertCountsQuery, AlertCountsQueryVariables>(AlertCountsDocument, options);
        }
export type AlertCountsQueryHookResult = ReturnType<typeof useAlertCountsQuery>;
export type AlertCountsLazyQueryHookResult = ReturnType<typeof useAlertCountsLazyQuery>;
export type AlertCountsQueryResult = Apollo.QueryResult<AlertCountsQuery, AlertCountsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ShipmentDocument = gql`
    query Shipment($id: Int) {
  shipment(id: $id) {
    ...Shipment
  }
}
    ${ShipmentFragmentDoc}`;

/**
 * __useShipmentQuery__
 *
 * To run a query within a React component, call `useShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShipmentQuery(baseOptions?: Apollo.QueryHookOptions<ShipmentQuery, ShipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentQuery, ShipmentQueryVariables>(ShipmentDocument, options);
      }
export function useShipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentQuery, ShipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentQuery, ShipmentQueryVariables>(ShipmentDocument, options);
        }
export type ShipmentQueryHookResult = ReturnType<typeof useShipmentQuery>;
export type ShipmentLazyQueryHookResult = ReturnType<typeof useShipmentLazyQuery>;
export type ShipmentQueryResult = Apollo.QueryResult<ShipmentQuery, ShipmentQueryVariables>;
export const ShipmentsDocument = gql`
    query Shipments($queries: ShipmentQueries!) {
  shipments(queries: $queries) {
    shipments {
      ...Shipment
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${ShipmentFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useShipmentsQuery__
 *
 * To run a query within a React component, call `useShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsQuery({
 *   variables: {
 *      queries: // value for 'queries'
 *   },
 * });
 */
export function useShipmentsQuery(baseOptions: Apollo.QueryHookOptions<ShipmentsQuery, ShipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentsQuery, ShipmentsQueryVariables>(ShipmentsDocument, options);
      }
export function useShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentsQuery, ShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentsQuery, ShipmentsQueryVariables>(ShipmentsDocument, options);
        }
export type ShipmentsQueryHookResult = ReturnType<typeof useShipmentsQuery>;
export type ShipmentsLazyQueryHookResult = ReturnType<typeof useShipmentsLazyQuery>;
export type ShipmentsQueryResult = Apollo.QueryResult<ShipmentsQuery, ShipmentsQueryVariables>;
export const AdminGroupDocument = gql`
    query AdminGroup($id: Int!) {
  adminGroup(id: $id) {
    ...Group
    users {
      ...User
    }
  }
}
    ${GroupFragmentDoc}
${UserFragmentDoc}`;

/**
 * __useAdminGroupQuery__
 *
 * To run a query within a React component, call `useAdminGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGroupQuery(baseOptions: Apollo.QueryHookOptions<AdminGroupQuery, AdminGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGroupQuery, AdminGroupQueryVariables>(AdminGroupDocument, options);
      }
export function useAdminGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGroupQuery, AdminGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGroupQuery, AdminGroupQueryVariables>(AdminGroupDocument, options);
        }
export type AdminGroupQueryHookResult = ReturnType<typeof useAdminGroupQuery>;
export type AdminGroupLazyQueryHookResult = ReturnType<typeof useAdminGroupLazyQuery>;
export type AdminGroupQueryResult = Apollo.QueryResult<AdminGroupQuery, AdminGroupQueryVariables>;
export const AdminGroupsDocument = gql`
    query AdminGroups {
  adminGroups {
    ...Group
  }
}
    ${GroupFragmentDoc}`;

/**
 * __useAdminGroupsQuery__
 *
 * To run a query within a React component, call `useAdminGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGroupsQuery(baseOptions?: Apollo.QueryHookOptions<AdminGroupsQuery, AdminGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGroupsQuery, AdminGroupsQueryVariables>(AdminGroupsDocument, options);
      }
export function useAdminGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGroupsQuery, AdminGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGroupsQuery, AdminGroupsQueryVariables>(AdminGroupsDocument, options);
        }
export type AdminGroupsQueryHookResult = ReturnType<typeof useAdminGroupsQuery>;
export type AdminGroupsLazyQueryHookResult = ReturnType<typeof useAdminGroupsLazyQuery>;
export type AdminGroupsQueryResult = Apollo.QueryResult<AdminGroupsQuery, AdminGroupsQueryVariables>;
export const AdminStatusConditionsDocument = gql`
    query AdminStatusConditions {
  adminStatusConditions {
    ...StatusCondition
  }
}
    ${StatusConditionFragmentDoc}`;

/**
 * __useAdminStatusConditionsQuery__
 *
 * To run a query within a React component, call `useAdminStatusConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminStatusConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminStatusConditionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminStatusConditionsQuery(baseOptions?: Apollo.QueryHookOptions<AdminStatusConditionsQuery, AdminStatusConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminStatusConditionsQuery, AdminStatusConditionsQueryVariables>(AdminStatusConditionsDocument, options);
      }
export function useAdminStatusConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminStatusConditionsQuery, AdminStatusConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminStatusConditionsQuery, AdminStatusConditionsQueryVariables>(AdminStatusConditionsDocument, options);
        }
export type AdminStatusConditionsQueryHookResult = ReturnType<typeof useAdminStatusConditionsQuery>;
export type AdminStatusConditionsLazyQueryHookResult = ReturnType<typeof useAdminStatusConditionsLazyQuery>;
export type AdminStatusConditionsQueryResult = Apollo.QueryResult<AdminStatusConditionsQuery, AdminStatusConditionsQueryVariables>;