import { Box, BoxProps, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from "@chakra-ui/react";
import { faCog, faLock, faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useRecoilValue } from "recoil";

import { NextLink } from "~/components/alias/NextLink";
import { pagesPath } from "~generated/$path";
import { useConfirm } from "~hooks/useConfirm";
import { useMe } from "~hooks/useMe";
import { useLogout } from "~libs/firebase";
import { firebaseUserState } from "~recoil/atoms/firebaseUserState";

type Props = {};

export const AccountMenu: React.VFC<Props & BoxProps> = ({ ...props }) => {
  const { firebaseUser } = useRecoilValue(firebaseUserState);
  const confirm = useConfirm();
  const logout = useLogout();
  const { group } = useMe();

  const onLogoutClick = async () => {
    if (!(await confirm("ログアウトしてもよろしいですか？"))) return;

    logout();
  };

  if (!firebaseUser) return null;

  return (
    <Box {...props}>
      <Menu>
        <MenuButton
          as={IconButton}
          backgroundColor="white"
          color="primary"
          h={8}
          icon={<FontAwesomeIcon icon={faUser} />}
          minH={8}
          minW={8}
          rounded="full"
          w={8}
        />
        <MenuList>
          <Box color="muted" ml={4}>
            <Box fontWeight={"bold"}>{group?.name}</Box>
            <Box>{firebaseUser.email}</Box>
          </Box>

          <MenuDivider />

          <NextLink href={pagesPath.account.$url()} legacyBehavior passHref>
            <MenuItem as="a" icon={<FontAwesomeIcon icon={faCog} />}>
              設定
            </MenuItem>
          </NextLink>

          {group?.admin && (
            <NextLink href={pagesPath.admin.$url()} legacyBehavior passHref>
              <MenuItem as="a" icon={<FontAwesomeIcon icon={faLock} />}>
                管理画面
              </MenuItem>
            </NextLink>
          )}

          <MenuItem icon={<FontAwesomeIcon icon={faSignOutAlt} />} onClick={onLogoutClick}>
            ログアウト
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
