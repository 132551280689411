import { Box, Flex } from "@chakra-ui/react";
import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { NextLink } from "~/components/alias/NextLink";
import { CsvDownloadMenu } from "~/components/page/shipments/CsvDownloadMenu";
import { Shipments } from "~/components/page/shipments/Shipments";
import { pagesPath } from "~generated/$path";

export default function TopPage() {
  return (
    <Box pb={10}>
      <Flex flexWrap="wrap" gap="4" justifyContent="flex-end">
        <CsvDownloadMenu />

        <NextLink href={pagesPath.shipments.csv.$url()} legacyBehavior passHref>
          <ChakraButton as="a" leftIcon={<FontAwesomeIcon icon={faUpload} />}>
            CSV アップロード
          </ChakraButton>
        </NextLink>

        <NextLink href={pagesPath.shipments.new.$url()} legacyBehavior passHref>
          <ChakraButton as="a" leftIcon={<FontAwesomeIcon icon={faPlus} />}>
            登録
          </ChakraButton>
        </NextLink>
      </Flex>

      <Shipments mt={10} />
    </Box>
  );
}
