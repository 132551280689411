import { extendTheme } from "@chakra-ui/react";

import { colors } from "./colors";
import { components } from "./components";
import { styles } from "./styles";

const overrides = {
  breakpoints: {
    lg: "992px",
    md: "768px",
    sm: "576px",
    xl: "1200px",
  },
  colors,
  components,
  fontSizes: {
    h1: "2rem",
    h2: "1.8rem",
    h3: "1.6rem",
    h4: "1.4rem",
    h5: "1.2rem",
    h6: "1rem",
  },
  fonts: {
    body: "'Noto Sans JP', sans-serif",
    heading: "'Noto Sans JP', sans-serif",
  },
  sizes: {
    11: "2.75rem",
  },
  styles,
};

export default extendTheme(overrides);
